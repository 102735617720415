import Vue from 'vue';
import VueRouter from 'vue-router';

const Index = () => import(/* webpackChunkName:'Index' */ '@pages/Index/Index');
const Home = () => import(/* webpackChunkName:'home' */ '@pages/Home/Index');
const ApplySeal = () => import(/* webpackChunkName:'ApplySeal' */ '@pages/ApplySeal/Index');
const LocalizationPlan = () => import(/* webpackChunkName:'LocalizationPlan' */ '@pages/LocalizationPlan/Index');
const About = () => import(/* webpackChunkName:'About' */ '@pages/About/Index');
const ProductIndex = () => import(/* webpackChunkName:'Product' */ '@pages/Product/Index');
const SealMaterial = () => import(/* webpackChunkName:'SealMaterial' */ '@pages/Product/SealMaterial');
const SealingEquipment = () => import(/* webpackChunkName:'SealingEquipment' */ '@pages/Product/SealingEquipment');
const ResourcesDownload = () => import(/* webpackChunkName:'Down' */ '@pages/download/Index');
const ContactUs = () => import(/* webpackChunkName:'ContactUs' */ '@pages/About/ContactUs');
const EsealIndex = () => import(/* webpackChunkName:'Eseal' */ '@pages/eseal/Index');
const LnzcIndex = () => import(/* webpackChunkName:'lnzc' */ '@pages/lnzc/Index');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Index,
  },
  {
    path: '/Home',
    component: Home,
  },
  {
    path: '/ApplySeal',
    component: ApplySeal,
  },
  {
    path: '/lnzc',
    component: LnzcIndex,
  },
  {
    path: '/LocalizationPlan',
    component: LocalizationPlan,
  },
  {
    path: '/About',
    component: About,
  },
  {
    path: '/ContactUs',
    component: ContactUs,
  },
  {
    path: '/eseal',
    component: EsealIndex,
  },
  {
    path: '/Product',
    component: ProductIndex,
  },
  {
    path: '/Product/SealMaterial',
    component: SealMaterial,
  },
  {
    path: '/Product/SealingEquipment',
    component: SealingEquipment,
  },
  {
    path: '/ResourcesDownload',
    component: ResourcesDownload,
  },
];

const router = new VueRouter({
  routes,
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
