const { localStorage } = window;

export default class Storage {
  static getItem(key) {
    return localStorage.getItem(key);
  }

  static setItem(key, value) {
    localStorage.setItem(key, value);
  }

  static removeItem(key) {
    localStorage.removeItem(key);
  }

  static getToken() {
    return localStorage.getItem('token');
  }

  static clearToken() {
    localStorage.removeItem('token');
  }

  static clear() {
    localStorage.clear();
  }
}
