import Vue from 'vue';
import {
  Pagination, Button, Input, Badge, Icon, Menu,
  Table, Checkbox, Modal, message, Layout, Tree, LocaleProvider, ConfigProvider,
  FormModel, Radio, Drawer, Select,
} from 'ant-design-vue';

Vue.use(LocaleProvider);
Vue.use(ConfigProvider);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Input);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Badge);
Vue.use(Table);
Vue.use(Checkbox);
Vue.use(Layout);
Vue.use(Tree);
Vue.use(Modal);
Vue.use(FormModel);
Vue.use(Radio);
Vue.use(Drawer);
Vue.use(Select);

Vue.prototype.$message = message;
Vue.prototype.$modal = Modal;
