import storage from '@helper/storage';
import {
  A_COMMON_GET_TOKEN,
  M_COMMON_SET_TOKEN,
  A_COMMON_CLEAR_TOKEN,
} from '../constance/constance.common';

const state = sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')) : {
  token: '',
  mobileWidth: 760,
};
console.log(state);
export default {
  namespaced: true,
  state,
  mutations: {
    [M_COMMON_SET_TOKEN](state, token) {
      state.token = token;
    },
  },
  actions: {
    /* 获取用户token */
    async [A_COMMON_GET_TOKEN]({ commit, dispatch }, userToken) {
      if (userToken) {
        console.log(dispatch);
        commit(M_COMMON_SET_TOKEN, userToken);
      }
    },
    [A_COMMON_CLEAR_TOKEN]: {
      root: true,
      handler({ commit }) {
        commit(M_COMMON_SET_TOKEN, '');
        storage.clearToken();
        return true;
      },
    },
  },
};
